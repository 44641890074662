body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.newline{
  white-space: pre-wrap;
}

#portfolio h1{
  font-size: 2rem;
}

.feature-item{
  text-align: center;
  margin-top:2.5em;
  padding-left:2em;
  padding-right:2em;
}

.center{
  text-align: center;
}

.warning{
  color: red;
}

